import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import theme from "../styles";

const About = () => (
  <AboutWrapper>
    <h1>Building an app? Let me help with that.</h1>
    <p>Hello, I'm Diana and I'm a Software Engineer.</p>
    <p>I'm interested in projects that bring real solutions to users' needs.</p>
    <p>
      To see some of the value I've delivered in the last six months, {" "}
      <br />
      <Link to="/portfolio">check out my work here.</Link>
    </p>
  </AboutWrapper>
);

export default About;

const AboutWrapper = styled.div`
  margin: 0 auto;
  width: 800px;
  padding: 10% 0;
  @media (max-width: 800px) {
    width: 100%;
    padding: 10% 2.5rem;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 2;
    color: ${theme.colors.lightYellow};
    @media (max-width: 800px) {
      line-height: normal;
      padding: 2.5rem 0;
    }
  }

  p {
    font-size: 2.4rem;
    font-weight: 200;
    color: ${theme.colors.lightYellow};
    padding-bottom: 1.5rem;
  }

  a {
    background: linear-gradient(to bottom, yellow 0%, white 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 2px;
    font-size: 2.4rem;
    font-weight: 300;
    background: ${theme.colors.lightYellow};
    color: ${theme.colors.backgroundPink};
    padding: 0 1rem;
    text-decoration: none;
    transition: background-size 0.2s;
    &:hover {
      background-size: 4px 50px;
      color: ${theme.colors.backgroundOrange};
      cursor: pointer;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }
  br {
    @media (max-width: 800px) {
      display: none;
    }
  }
`;
