import React from "react";
import { Helmet } from 'react-helmet';
import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import About from "../components/About";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Home | Diana Ignatescu</title>
      <link rel="canonical" href="https://dianaignatescu.com/" />
    </Helmet>
    <Navigation />
    <About />
    <span />
  </Layout>
);
